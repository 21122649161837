import { DisplayData } from '~/lib/model';
import { Fragment, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Row } from '~/components/layout/Sections';

export default function DisplayDataGrid(props: {
  data: (DisplayData & { edit?: ReactNode })[];
  full?: boolean;
  small?: boolean;
  expand?: boolean;
}) {
  const [expanded, setExpanded] = useState(false);

  if (!props.data.length) {
    return <></>;
  }

  const sorted = props.data.sort((a, b) => {
    if (a.highlight && !b.highlight) {
      return -1;
    }

    if (b.highlight && !a.highlight) {
      return 1;
    }

    return 0;
  });

  return (
    <div
      className={classNames('flex flex-wrap justify-self-start', {
        'text-sm gap-y-4 gap-x-10': !props.small,
        'text-xs gap-y-2 gap-x-4': props.small,
      })}
    >
      {sorted.map(({ name, value, edit, highlight }) => {
        const text = Array.isArray(value) ? value.join(',') : `${value}`;
        const expandable = text.length > 50;

        return (
          <div
            key={name}
            className={props.full ? 'basis-full' : undefined}
            onClick={(e) => {
              if (document.getSelection()?.type === 'Range') {
                // allow user to highlight text without collapsing
                return;
              }

              setExpanded(!expanded);
            }}
          >
            <Row between>
              <div
                className={classNames('font-semibold text-xs tracking-widest', {
                  'text-blue-500': highlight,
                  'text-theme-faint': !highlight,
                })}
              >
                {name}
              </div>
              {edit}
            </Row>
            <div
              className={classNames('text-gray-600', {
                'line-clamp-3': !expanded && !props.expand,
                'cursor-s-resize': !expanded && expandable,
                'cursor-n-resize': expanded && expandable,
              })}
            >
              {text.split('@').map((t, index) =>
                index === 0 ? (
                  t.split(' ').map((word, index) =>
                    word.includes('http') ? (
                      <span key={index} className="break-all">
                        {word}{' '}
                      </span>
                    ) : (
                      <Fragment key={index}>{word} </Fragment>
                    ),
                  )
                ) : (
                  <>
                    <span className="break-all">@</span>
                    {t}
                  </>
                ),
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}
